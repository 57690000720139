"use client";
import { placeHolderContactBase64 } from "@/components/directory/contact/detail/constants";
import { cn } from "@costar-ui/cosmos";
import Image from "next/image";
import { SyntheticEvent, useMemo, useState } from "react";
import { ObjectFit } from "@/components/common/types/types";
import Link from "next/link";

type ContactImageProps = {
  imageUrl?: string;
  imageAlt: string;
  articleLinkUrl?: string;
  automationId?: string;
  imagePriority?: boolean;
  objectFit?: ObjectFit;
  className?: string;
};

/**
 * @description
 * **An atomic component that returns a fill Image and links to the articlePage**
 *
 * @property {string} **imageUrl**: URL for the image
 * @property {string} **imageAlt**: Description for the image to be used as image alt
 * @property {string} **articleLinkUrl** url that will be routed to on image click
 * @property {boolean} **imagePriority**: preload (when true) the image
 * @property {ObjectFit} **objectFit** choose between following objectFit options
 *  - Cover [default]
 *  - Contain
 * @property {string} **className**: add className to image
 */
export const ContactImage = ({
  imageUrl,
  imageAlt,
  automationId,
  imagePriority,
  objectFit = ObjectFit.Cover,
  className,
  articleLinkUrl,
}: ContactImageProps) => {
  const [error, setError] = useState<SyntheticEvent<
    HTMLImageElement,
    Event
  > | null>(null);

  const placeholderImage = useMemo(
    () => (
      <div className="relative h-full">
        <Image
          src="/placeholder-person.svg"
          alt=""
          fill
          className={cn("rounded", className)}
        />
      </div>
    ),
    [className]
  );

  const image = useMemo(() => {
    if (!!imageUrl)
      return (
        <figure className="relative h-full">
          <Image
            automation-id={automationId}
            src={error ? "/placeholder-person.svg" : imageUrl}
            alt={imageAlt}
            placeholder="blur"
            blurDataURL={placeHolderContactBase64}
            fill
            onError={(e) => setError(e)}
            style={{ objectFit }}
            priority={!!imagePriority}
            sizes="(max-width: 768px) 100vw, 50vw"
            className={cn("rounded", className)}
          />
        </figure>
      );
  }, [
    automationId,
    className,
    error,
    imageAlt,
    imagePriority,
    imageUrl,
    objectFit,
  ]);

  const imageComponent = useMemo(() => {
    if (!!articleLinkUrl) {
      return <Link href={articleLinkUrl}>{image}</Link>;
    } else {
      return <>{image}</>;
    }
  }, [articleLinkUrl, image]);

  return <>{imageUrl ? imageComponent : placeholderImage}</>;
};
