import { cn } from "@costar-ui/cosmos";
import Image from "next/image";
import { VideoIconSize } from "@/components/common/types/types";

type VideoOverlayProps = {
  iconSize?: VideoIconSize;
  alt: string;
};

export const VideoOverlay = ({
  iconSize = VideoIconSize.Default,
  alt,
}: VideoOverlayProps) => {
  const videoOverLay = (
    <div className="absolute h-full w-full bg-gradient-to-b from-primary/0 from-60% to-primary/100">
      <div
        className={cn("absolute bottom-1 left-1", {
          "h-6 w-6 sm:h-12 sm:w-12": iconSize === VideoIconSize.Default,
          "h-12 w-12": iconSize === VideoIconSize.Large,
          "h-6 w-6": iconSize === VideoIconSize.Small,
        })}
      >
        <Image src={"/play-icon.svg"} alt={alt} fill />
      </div>
    </div>
  );

  return videoOverLay;
};
