"use client";

import Image from "next/image";
import Link from "next/link";
import { VideoOverlay } from "./VideoOverlay";
import { ObjectFit, VideoIconSize } from "@/components/common/types/types";
import { cn } from "@costar-ui/cosmos";
import { SyntheticEvent, useState } from "react";

type ArticleImageProps = {
  imageUrl?: string;
  imageAlt: string;
  articleLinkUrl?: string;
  automationId?: string;
  imagePriority?: boolean;
  showVideoOverlay?: boolean;
  videoIconSize?: VideoIconSize;
  objectFit?: ObjectFit;
  disableBackgroundPlaceholder?: boolean;
  ariaLabel?: string;
  sizes?: string;
};

/**
 * @description
 * **An atomic component that returns a fill Image and links to the articlePage**
 *
 * @property {string} **imageUrl**: URL for the image
 * @property {string} **imageAlt**: Description for the image to be used as image alt
 * @property {string} **articleLinkUrl**: URL for the article page
 * @property {boolean} **imagePriority**: preload (when true) the image
 * @property {boolean} **showVideoOverlay**: add blue gradient and play icon
 * to image in the presence of a video id
 * @property {string} **aria-label** adds aria-label to links
 * @property {VideoIconSize} **videoIconSize**: choose between following icon sizes
 *  - Default (24x24px in mobile screens, 48x48px in desktop screens)
 *  - Large (48x48px in all screen sizes)
 *  - Small (24x24px in all screen sizes)
 * @property {sizes} **sizes**: NextJs sizes prop to control the size of the image at different breakpoints
 * @property {ObjectFit} **objectFit** choose between following objectFit options
 *  - Cover
 *  - Contain [default]
 * @example
 * <ArticleImage
 *    imageUrl={"www.imageurl.com/image.jpg"}
 *    articleLinkUrl={"/article/11111"}
 *    description={"image alt text"}
 *    imagePriority={true}
 *
 * />
 */
export const ArticleImage = (props: ArticleImageProps) => {
  const {
    imageUrl,
    imageAlt,
    articleLinkUrl,
    automationId,
    imagePriority,
    showVideoOverlay,
    videoIconSize,
    objectFit = ObjectFit.Cover,
    disableBackgroundPlaceholder = false,
    ariaLabel,
    sizes = "(max-width: 768px) 100vw, 50vw",
  } = props;

  const [error, setError] = useState<SyntheticEvent<
    HTMLImageElement,
    Event
  > | null>(null);

  const imageComponent = (
    <>
      <Image
        automation-id={automationId}
        src={error || !imageUrl ? "/article-image-placeholder.jpeg" : imageUrl}
        alt={imageAlt}
        fill
        style={{
          objectFit,
          background: disableBackgroundPlaceholder ? undefined : "#eaeaea",
        }}
        onError={(e) => setError(e)}
        priority={!!imagePriority}
        sizes={sizes}
      />
      {showVideoOverlay && (
        <VideoOverlay iconSize={videoIconSize} alt={imageAlt} />
      )}
    </>
  );
  return (
    <div
      className={cn(
        "relative h-full",
        disableBackgroundPlaceholder !== true && "bg-neutral-light"
      )}
    >
      {!!articleLinkUrl ? (
        <Link
          href={articleLinkUrl}
          aria-label={ariaLabel}
          className="absolute inset-0"
        >
          {imageComponent}
        </Link>
      ) : (
        <>{imageComponent}</>
      )}
    </div>
  );
};
