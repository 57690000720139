export enum ObjectFit {
  Cover = "cover",
  Contain = "contain",
}

export enum VideoIconSize {
  Default,
  Large,
  Small,
}
